.App {
  
}
body {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.header {
}
.header .logo-text {
  margin-top: 15px;
  font-size: 2rem;
  line-height: 1;
  font-weight: 300;
  letter-spacing: 0.5px;
  font-family: "Poppins", sans-serif;
  color: #16507b;

}
header {
  padding: 15px 0;
  border-bottom: 1px solid #e6f2fb; 
}
.header .tagline {
  font-size: 1rem;
  color: #000;
}
.body .banner {
  text-align: center;
  padding-top: 20px;
}
.body {
  width: 100%;
  position: relative;
  background: url("images/hero-bg.jpg") top center;
    background-attachment: scroll;
    background-size: auto;
  background-size: cover;
  position: relative;
}
.body::before{
  content: "";
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
.body .banner {
  position: relative;
  z-index: 999;
}
.body .banner .banner-text {
  margin: 0;
  font-size: 56px;
  font-weight: 700;
  line-height: 72px;
  color: #124265;
  font-family: "Poppins", sans-serif;
}
.body .banner h2 {
  color: #5e5e5e;
  margin: 10px 0 0 0;
  font-size: 22px;
  font-family: 'Raleway', sans-serif;
}
.body .icon-boxes {
  margin-top:50px;
}
.body .icon-boxes .icon-box {
  padding: 50px 30px;
  position: relative;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 0 29px 0 rgba(18, 66, 101, 0.08);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  z-index: 1;
  width: 100%;
  text-align: justify;
}
.icon-box h3 {
  text-align: center;
  color: #124265;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.copyright {
  text-align: center;
  
}